import React, { Component } from 'react'
import Api from '../../Services/Cloud';
import moment from 'moment';
import { toast } from 'react-toastify';
import DataTable from '../../components/DataTable';
import { parseMoney, removeAcento } from '../../Util/Functions';
import Loading from '../../components/Loading';
import $ from 'jquery';

let userType = localStorage.getItem('userType');

const columns = [
    { 
        title: "Responsável",
        data: null,
        render: (data, type, row) => { 
            if (type === 'display') {
                return row.user.name;
            } else {
                return removeAcento(row.user.name);
            }
        } 
    },
    { 
        title: "Aluno",
        data: null,
        render: (data, type, row) => { 
            if (type === 'display') {
                return row.user.has_dependent ? row.user.minor_name : row.user.name;
            } else {
                return row.user.has_dependent ? removeAcento(row.user.minor_name) : removeAcento(row.user.name);
            }
        } 
    },
    { 
        title: "Cadastrado em",
        data: null,
        render: (data, type, row) => { 
            return moment(row.user.createdAt).format('DD/MM/YYYY');
        } 
    },
    { 
        title: "Pedido em",
        data: null,
        render: (data, type, row) => { 
            return moment(row.createdAt).format('DD/MM/YYYY');
        } 
    },
    { 
        title: 'Telefone',
        data: null,
        render: (data, type, row) => { 
            return row.user.phone || '';
        } 
    },
    { 
        title: 'Whatsapp',
        data: null,
        render: (data, type, row) => { 
            return row.user.whatsapp_phone || '';
        } 
    },
    { 
        title: 'Fone Recados',
        data: null,
        render: (data, type, row) => { 
            return row.user.friend_phone || '';
        } 
    },
    { 
        title: 'Email',
        data: null,
        render: (data, type, row) => { 
            return row.user.email || '';
        } 
    },
    { 
        title: 'CPF',
        data: null,
        render: (data, type, row) => { 
            return row.user.cpf.replace(/\D/g, '');
        } 
    },
    { 
        title: 'Desconto',
        data: null,
        render: (data, type, row) => { 
            return row.payments.find(x => x.discount).discount + '%';
        } 
    },
    { 
        title: 'Valor',
        data: null,
        render: (data, type, row) => { 
            return parseMoney(row.payments?.find(x => x.discount).final_price);
        } 
    },
    { 
        title: 'Instituição',
        data: null,
        render: (data, type, row) => { 
            if (type === 'display') {
                return row.scholarship.institution.name;
            } else {
                return 's';
            }
        } 
    },
    { 
        title: 'Curso',
        data: null,
        render: (data, type, row) => { 
            if (type === 'display') {
                return row.scholarship.course.name;
            } else {
                return removeAcento(row.scholarship.course.name);
            }
        } 
    },
    { 
        title: 'Turno',
        data: null,
        render: (data, type, row) => { 
            return row.scholarship.shift;
        } 
    },
    { 
        title: 'Modalidade',
        data: null,
        render: (data, type, row) => { 
            return row.scholarship.type;
        } 
    },
    {
        title: 'Situação',
        data: null,
        render: (data, type, row) => {
            if (row.payments.find(x => x.status === 'PAID')) {
                return 'Matriculado ';
            } else if (row.payments.find(x => x.status === 'REFUNDED')) {
                const refound = row.payments.find(x => x.status === 'REFUNDED');
                return `Estornado - ${refound.percent}%`;
            } else if (row.payments.find(x => x.status === 'CANCELLED')) {
                return 'Cancelado';
            } else if (row.payments.length > 0) {
                return 'Iniciou pagamento';
            }
            return type === 'display' ? 'Não iniciou pagamento.' : 'Nao iniciou pagamento.';
        }
    },
    {
        title: 'Link de pagamento',
        data: null,
        render: (data, type, row) => {
            console.log(row);   
            if (row.is_renew) {
                if (row.payments.find(x => x.status === 'WAITING') && row.payments.find(x => x.status === 'WAITING').url_boleto) {
                    return `<a href="${row.payments.find(x => x.status === 'WAITING').url_boleto}" target="_blank" style="color: blue;">${row.payments.find(x => x.status === 'WAITING').url_boleto}</a>`;
                } else if (row.payments.find(x => x.status === 'PAID')) {
                    return `<span style="color: green;">PAGO</span>`;
                }
            }
           return '-';
        },
    },
    {
        title: 'Renovar',
        data: null,
        render: (data, type, row) => {
            if (row.expired) {
                return '<a class="action-button" data-toggle="modal"><i class="fas fa-undo action-icon renew-scholarship"></i></span></a>';
                }  else {
                if (row.payments.find(x => x.status === 'WAITING')) {
                    return '<span style="color: blue;">Aguardando pagamento <a class="action-button" data-toggle="modal"><i class="fas fa-undo action-icon renew-scholarship"></i></span></a></span>';
                } else if (row.payments.find(x => x.status === 'PAID')) {
                    return '<span style="color: green;">PAGO</span>';
                }
                return '-';
            }
        }
    }
];

const columnDefs = [
    {
        targets: 3,
        visible: userType === 'admin'
    },
    {
        targets: 4,
        visible: userType === 'admin'
    },
    {
        targets: 5,
        visible: userType === 'admin'
    },
    {
        targets: 6,
        visible: userType === 'admin'
    },
    {
        targets: -1,
        visible: userType === 'admin',
        defaultContent: '<a class="action-button" data-toggle="modal"><i class="fas fa-undo action-icon renew-scholarship"></i></span></a>'
    }
];

export default class Financial extends Component {
    constructor(props) {
        super(props)

        this.state = {
            students: [],
            requesting: false,
            user_id: null,
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = async (update) => {
        try {
            this.setState({ requesting: true });
            let url = '/order/expired';
            console.log(url);
            let response = await Api.get(url);
            let studentsExpired = response.data.orders || [];
    
            console.log(studentsExpired);
            
            // Atualize o estado diretamente, removendo reloadTableData
            this.setState({
                students: studentsExpired,
                requesting: false
            });
    
            // Remover a linha abaixo, pois DataTable atualiza ao receber novas props
            this.table.reloadTableData(studentsExpired);


        } catch (error) {
            this.setState({ requesting: false });
            console.error(error);
        }
    }

    handleTableClick = (e, dataTable) => {
        let target = $(e.target);
        var current_row = target.parents('tr');
        if (current_row.hasClass('child')) {
            current_row = current_row.prev();
        }
        var data = dataTable.row(current_row).data();
        if (e.target.className.includes('renew-scholarship')) {
            this.handleRenewScholarship(data);
        }
    }

    handleRenewScholarship = async (data) => {
        try {
            console.log(data);
            this.setState({ requesting: true });
            let response = await Api.post('/payment/renew', { user_id: data.user.id, scholarship_id: data.scholarship.id });
            

            this.handleSuccess(response.data.message);
        } catch (error) {
            this.handleError(error.response.data.message);
        } finally {
            this.setState({ requesting: false });
        }
    }


    handleSuccess = (message) => {
        this.getData(true);
        toast.success(message);
    }

    handleError = (message) => {
        toast.error(message);
    }

    render() {
        if (this.state.requesting) return <Loading />;
        return (
            <>
                {userType === 'admin' && (
                    <div className="mt-2 w-100">
                        <DataTable
                            data={this.state.students}
                            columns={columns}
                            options={{
                                buttons: ['pdf', 'print'],
                                responsive: true,
                                columnDefs: columnDefs,
                                destroy: true
                            }}
                            handleActionClick={(e, dataTable) => { this.handleTableClick(e, dataTable) }}
                            ref={ref => this.table = ref}
                        />
                    </div>
                )}
            </>
        )
    }
}
